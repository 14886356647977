import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { initFindingGameState } from "../../jobs/FindingGame/FindingGameWork";
import { useExistState } from "../../shared/Hyundux/index";
import SmileBadge from "../common/SmileBadge/index";
function modeDependency(mode) {
    if (mode === "PIXEL") {
        return {
            findingWord: "숨겨진 픽셀",
            tooltipTitle: "픽셀 디자인",
            tooltipContent: "픽셀처럼 사각형 모양을 띄고 있는 디자인입니다.",
        };
    }
    else {
        return {
            findingWord: "숨겨진 로봇 뱃지",
            tooltipTitle: "로봇 뱃지와 유사한 디자인",
            tooltipContent: "커다랗고 동그란 두 눈이 특징입니다.",
        };
    }
}
const FindingGameInfo = () => {
    const state = useExistState(initFindingGameState);
    const [isTooltipShowing, setIsTooltipShowing] = useState(false);
    state;
    function clickToolTip() {
        setIsTooltipShowing((prev) => !prev);
    }
    const modeData = modeDependency(state.gameType);
    const tooltip = isTooltipShowing ? (_jsx(_Fragment, { children: _jsxs("div", { className: "shadow-[0px_6px_10px_0px_rgba(0,0,0,0.15)] w-full bg-[#F7F7F7] rounded-lg relative mt-[16px] leading-[150%]", children: [_jsx("img", { src: "/assets/xButton.svg", className: "absolute w-[16px] h-[16px] right-[14px] top-[14px]", onClick: clickToolTip }), _jsxs("div", { className: "p-6", children: [_jsx("div", { className: "absolute left-5 top-[-10px] w-0 h-0 border-b-[10px] border-r-[10px] border-r-transparent border-l-[10px] border-l-transparent bg-transparent border-[#F7F7F7]" }), "\uCE90\uC2A4\uD37C \uC77C\uB809\uD2B8\uB9AD\uC758 \uD575\uC2EC \uB514\uC790\uC778 \uC694\uC18C\uB85C", _jsx("br", {}), modeData.tooltipContent] })] }) })) : (_jsx(_Fragment, {}));
    const badges = new Array(2).fill(0).map((answer, index) => {
        const badgeType = index == 0 ? "blue" : "orange";
        if (state.showingAnswers.length > index) {
            return (_jsx(SmileBadge, { isSelected: true, badgeType: badgeType, width: 110 }, answer.id));
        }
        else {
            return (_jsx(SmileBadge, { isSelected: false, badgeType: badgeType, width: 110 }, answer.id));
        }
    });
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("p", { className: "font-galmuri text-l text-[#1C1A1B]", children: "\uB098\uB97C \uCC3E\uC544\uBD10 \uC774\uBCA4\uD2B8" }), _jsxs("p", { className: "text-3xl leading-normal weight font-black text-[#1C1A1B]", children: ["\uC67C\uCABD\uC5D0 \uCE90\uC2A4\uD37C \uC77C\uB809\uD2B8\uB9AD\uC5D0\uC11C", _jsx("br", {}), _jsxs("span", { className: "text-[#00AAD2]", children: [modeData.findingWord, " 2\uAC1C"] }), "\uB97C \uCC3E\uC544\uC8FC\uC138\uC694!"] }), _jsxs("div", { className: "text-[#626262] flex", children: [_jsxs("div", { className: "text-[#444444] font-bold border-b-2 border-b-[black] border-solid flex", onClick: clickToolTip, children: [modeData.tooltipTitle, _jsx("img", { src: "/assets/questionIcon.svg", alt: "hyundaiLogo", width: 15, height: 15, className: "ml-0.5 mr-0.5" })] }), "\uC774 \uB4E4\uC5B4\uAC04 \uC694\uC18C\uAC00 \uC228\uC5B4\uC788\uC5B4\uC694."] }), tooltip, _jsx("div", { className: "bg-[#F7F7F7] rounded-[20px]", children: _jsx("div", { className: "flex justify-center items-center gap-6 pt-[30px] pb-[30px] mt-[20px]", children: badges }) }), state.showingHint.length != 0 ? (_jsx("div", { className: "flex justify-center items-center", children: _jsx("img", { src: "/assets/hintTalk.svg", alt: "hintTalk" }) })) : null] }) }));
};
export default FindingGameInfo;
