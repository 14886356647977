import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
const ShowCasper = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: "smooth",
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex w-full h-screen justify-center items-center relative", children: [_jsxs("div", { className: "flex flex-col absolute z-20 top-[200px] left-[200px]", children: [_jsx("h1", { className: "text-white text-[80px] font-bold", children: "CASPER Electric" }), _jsx("p", { className: "text-white text-[32px]", children: "\uC804\uB825\uC744 \uB2E4\uD574, CASPER Electric \uCD9C\uC2DC" }), _jsxs("div", { className: "flex flex-row mt-10", children: [_jsx("button", { className: "bg-[#002C5F] w-[138px] h-[40px] text-white", children: _jsx("a", { href: "https://casper.hyundai.com/", children: "\uC790\uC138\uD788 \uC54C\uC544\uBCF4\uAE30" }) }), _jsx("button", { className: "bg-[#002C5F] w-[138px] h-[40px] ml-4 text-white", onClick: () => scrollToSection("targetSection"), children: "\uC774\uBCA4\uD2B8 \uBC14\uB85C\uAC00\uAE30" })] })] }), _jsx("div", { children: _jsx("img", { src: "/assets/eventInfoPage2.svg", alt: "eventInfoPage2", className: "absolute top-0 left-0 w-full h-screen object-cover object-bottom-center" }) })] }) }));
};
export default ShowCasper;
