import { createState, makePayLoad } from "../../shared/Hyundux";
const WORK_NAME = "Overlay";
const initOverlayState = createState(WORK_NAME, {
    isShowing: false,
    index: 0,
    isOnButton: false,
});
// define reducer
const overlayReducer = {
    type: WORK_NAME,
    reducer: async function reducer(state, action) {
        const payLoad = state.payload;
        switch (action.actionName) {
            case "toggleOverlay": {
                return makePayLoad(state, { isShowing: !payLoad.isShowing, index: 0 });
            }
            case "nextPage": {
                const actionPayload = action.payload;
                return makePayLoad(state, {
                    index: payLoad.index + actionPayload.amount,
                });
            }
            default:
                return state;
        }
    },
};
// actions
const action = {
    toggleOverlay: () => {
        return {
            type: WORK_NAME,
            actionName: "toggleOverlay",
        };
    },
    nextPage: (amount = 1) => {
        return {
            type: WORK_NAME,
            actionName: "nextPage",
            payload: {
                amount: amount,
            },
        };
    },
};
export { action, initOverlayState, overlayReducer };
