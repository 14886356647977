import removeFirst from "./Util/RemoveFirst";
import replaceFirst from "./Util/ReplaceFirst";
import areEqual from "../../utils/areEqual";
export function createStore() {
    return {
        states: [],
        reducers: [],
        subscribeList: new Map(),
        dispatch: async function (action) {
            const reducer = this.reducers.filter((reducer) => reducer.type == action.type)[0].reducer;
            const { removed, newArray } = removeFirst(this.states, 
            // eslint-disable-next-line
            // @ts-ignore
            // eslint-disable-next-line
            (state) => state.type == action.type);
            const newState = await reducer(removed, action);
            // 여기서 모든것을 바로 state를 적용하는것이 아니라 이게 다른 state도 propagation하는지도 확인해야함
            this.states = [...newArray, newState];
            this.publish(removed, newState);
        },
        publish: function (oldState, state) {
            const publishedCallBack = this.subscribeList.get(state.type);
            if (publishedCallBack !== undefined && !areEqual(oldState, state)) {
                publishedCallBack(state);
            }
        },
        subscribe: function (state, reducer = null, cb) {
            this.states = replaceFirst(this.states, state, (element) => element.type == state.type);
            if (reducer != null) {
                this.reducers = replaceFirst(this.reducers, reducer, (element) => element.type == state.type);
            }
            this.subscribeList.set(state.type, cb);
        },
    };
}
const store = createStore();
export default store;
