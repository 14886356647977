import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { action } from "../../../jobs/Overlay/OverlayWork";
import { store } from "../../../shared/Hyundux";
import SmileBadge from "../../../components/common/SmileBadge/index";
const SuccessEnterContent = () => {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col justify-center items-center pt-20 pb-20", children: [_jsx("p", { className: "text-4xl font-black mb-3", children: "\uC751\uBAA8 \uC644\uB8CC!" }), _jsxs("span", { className: "text-center", children: ["\uCFE0\uD3F0\uC740 9\uC6D4 9\uC77C\uC5D0 \uC21C\uCC28\uC801\uC73C\uB85C \uC9C0\uAE09\uB420 \uC608\uC815\uC785\uB2C8\uB2E4.", _jsx("br", {}), "\uCC38\uC5EC\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."] }), _jsx("div", { className: "mt-[80px]", children: _jsx(SmileBadge, { width: 180, badgeType: "orange" }) }), _jsx("div", { className: "flex w-[521px] justify-center items-center px-20 py-5 bg-[#002C5F] mt-[60px]", onClick: () => {
                        store.dispatch(action.toggleOverlay());
                    }, children: _jsx("span", { className: "text-white text-[18px]", children: "\uD655\uC778" }) })] }) }));
};
export default SuccessEnterContent;
