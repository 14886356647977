// useAudio.tsx 파일에 훅 정의
import { useRef } from "react";
const useAudio = (soundSource) => {
    const audioRef = useRef(new Audio(soundSource));
    const statusRef = useRef("notStart");
    const playAudio = () => {
        audioRef.current.play().catch((error) => {
            console.error("Audio play error:", error);
        });
        statusRef.current = "playing";
    };
    const pauseAudio = () => {
        audioRef.current.pause();
        statusRef.current = "pause";
    };
    const resetAudio = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.volume = 1.0;
        audioRef.current.load();
    };
    return {
        audio: audioRef.current,
        status: statusRef.current,
        playAudio,
        pauseAudio,
        resetAudio,
    };
};
export default useAudio;
