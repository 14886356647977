import { useState } from "react";
import store from "../Store";
function useWork(initialState, reducer) {
    const [state, setState] = useState(initialState);
    store.subscribe(state, reducer, (newState) => {
        setState(newState);
    });
    return [state.payload, store.dispatch];
}
export default useWork;
