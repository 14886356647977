import store from "../Hyundux/Store";
import { createStory } from "./Story";
class Saga {
    constructor(store) {
        this.store = null;
        this.store = store;
    }
    async run(action, story, parameter = {}) {
        try {
            const runStroy = createStory(story, parameter);
            const asyncResult = await runStroy();
            this.store?.dispatch(action({ request: parameter, response: asyncResult }));
        }
        catch (e) {
            console.log(`Saga error: ${e}`);
            throw "some story is problem";
        }
    }
}
const saga = new Saga(store);
export { saga, Saga };
