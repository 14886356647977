import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import Footer from "../../components/common/Footer";
import Navigation from "../../components/common/Navigation";
import { EventPeriod, HowToEvent, LadingPageTitle, OpenEvent, SelectionMethod, } from "../../features/FindingGameLanding";
const FindingGameLandingPage = () => {
    const targetRef = useRef(null);
    const scrollToElement = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Navigation, {}), _jsx(LadingPageTitle, { onClick: scrollToElement }), _jsx(HowToEvent, {}), _jsx(SelectionMethod, {}), _jsx(EventPeriod, {}), _jsx(OpenEvent, { ref: targetRef }), _jsx(Footer, {})] }));
};
export default FindingGameLandingPage;
