import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import SmileBadge from "../../../components/common/SmileBadge/SmileBadge";
import { store } from "../../../shared/Hyundux";
import { action } from "../../../jobs/Overlay/OverlayWork";
const EnterContent = () => {
    const [timeLeft, setTimeLeft] = useState(3 * 60); // 3분을 초 단위로 변환
    const timeToString = () => {
        const minute = Math.floor(timeLeft / 60);
        const second = timeLeft % 60;
        const minuteStr = "0" + `${minute}`;
        const secondStr = second < 10 ? "0" + `${second}` : `${second}`;
        return minuteStr + ":" + secondStr;
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    // Todo: 여기 있는 store 제거하기
                    store.dispatch(action.toggleOverlay());
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    });
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col justify-center items-center pt-20 pb-20", children: [_jsx("p", { className: "text-4xl font-black mb-3", children: "\uCD95\uD558\uD569\uB2C8\uB2E4!" }), _jsxs("span", { className: "text-center", children: ["\uCE90\uC2A4\uD37C \uCC3E\uAE30 \uC774\uBCA4\uD2B8\uC5D0 \uB2F9\uCCA8\uB418\uC168\uC2B5\uB2C8\uB2E4.", _jsx("br", {}), "\uC804\uD654\uBC88\uD638\uB97C \uC785\uB825\uD558\uACE0 \uCFE0\uD3F0\uC744 \uBC1B\uC544\uAC00\uC138\uC694."] }), _jsx("div", { className: "mt-[80px]", children: _jsx(SmileBadge, { width: 180, badgeType: "orange" }) }), _jsx("div", { className: "flex w-[521px] justify-center items-center px-20 py-5 bg-[#002C5F] mt-[60px]", onClick: () => {
                        store.dispatch(action.nextPage());
                    }, children: _jsx("span", { className: "text-white text-[18px]", children: "\uC804\uD654\uBC88\uD638 \uC785\uB825\uD558\uB7EC \uAC00\uAE30" }) }), _jsxs("div", { className: "flex pt-[20px]", children: [_jsxs("span", { className: "text-[red] underline", children: [_jsx("span", { children: timeToString() }), "\uB0B4"] }), _jsx("span", { children: "\uC5D0 \uC785\uB825\uD558\uC9C0 \uC54A\uC73C\uBA74 \uBBF8\uB2F9\uCCA8\uC73C\uB85C \uAC04\uC8FC\uB418\uC5B4 \uC790\uB3D9 \uC885\uB8CC\uB429\uB2C8\uB2E4." })] })] }) }));
};
export default EnterContent;
