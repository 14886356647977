import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { action, initFindingGameState, } from "../../jobs/FindingGame/FindingGameWork";
import { useExistState, store } from "../../shared/Hyundux/index";
import { Link } from "../../shared/Hyunouter";
const FindingGameResult = () => {
    const state = useExistState(initFindingGameState);
    const currentAnswer = state.showingAnswers[state.answerIndex];
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col justify-center items-center h-screen", children: [_jsxs("div", { className: "w-10/12 mt-[80px]", children: [_jsx("p", { className: "font-galmuri", children: "\uB098\uB97C \uCC3E\uC544\uBD10 \uC774\uBCA4\uD2B8" }), _jsx("p", { className: "font-bold text-[28px] mt-[20px]", children: "\uB0B4\uAC00 \uCC3E\uC740 \uCE90\uC2A4\uD37C\uB4E4\uC744 \uC54C\uC544\uBCFC\uAE4C\uC694?" })] }), _jsxs("div", { className: "relative w-full h-[280px] mt-[25px]", children: [_jsxs("div", { className: "absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full", children: [_jsx("div", { className: "bg-[#000000] bg-opacity-50 w-[50px] h-[50px] flex justify-center items-center", onClick: () => {
                                        const index = (state.showingAnswers.length + state.answerIndex - 1) %
                                            state.showingAnswers.length;
                                        store.dispatch(action.changeShowingAnswer(index));
                                    }, children: _jsx("img", { src: "/assets/whiteLeftShevron.svg" }) }), _jsx("div", { className: "bg-[#000000] bg-opacity-50 w-[50px] h-[50px] flex justify-center items-center", onClick: () => {
                                        const index = (state.answerIndex + 1) % state.showingAnswers.length;
                                        store.dispatch(action.changeShowingAnswer(index));
                                    }, children: _jsx("img", { src: "/assets/whiteRightShevron.svg" }) })] }), _jsx("img", { src: currentAnswer.descriptionImageUrl, className: "object-cover object-center w-full h-full" })] }), _jsxs("div", { className: "w-10/12 mt-[20px]", children: [_jsx("p", { className: "font-bold text-[24px]", children: currentAnswer.title }), _jsx("p", { className: "mt-[25px]", children: currentAnswer.content })] }), _jsx("div", { className: "flex-grow" }), _jsx("div", { className: "bg-[#002C5F] flex justify-center items-center w-10/12 py-[14px] mb-[40px]", children: _jsx(Link, { to: "/findcasper", children: _jsx("p", { className: "text-[white] font-bold text-[18px]", children: "\uC774\uBCA4\uD2B8 \uD648\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30" }) }) })] }) }));
};
export default FindingGameResult;
