import React, { isValidElement } from "react";
const findChildrenElement = (elements, checkFn) => {
    let returnElement = null;
    React.Children.forEach(elements, (element) => {
        if (!isValidElement(element)) {
            return;
        }
        if (element.type === React.Fragment) {
            return;
        }
        if (checkFn(element)) {
            returnElement = element.props.element;
        }
    });
    return returnElement;
};
export default findChildrenElement;
