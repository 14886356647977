import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useEffect } from "react";
const RouterContext = createContext({
    path: "",
    changePath: () => undefined,
});
RouterContext.displayName = "RouterContext";
const Router = ({ children }) => {
    const [path, setPath] = useState(window.location.pathname);
    useEffect(() => {
        const handleLocationChange = () => {
            setPath(window.location.pathname);
        };
        window.addEventListener("popstate", handleLocationChange);
        return () => {
            window.removeEventListener("popstate", handleLocationChange);
        };
    }, []);
    const changePath = (newPath, isChangePath = true) => {
        if (path !== newPath) {
            if (isChangePath) {
                window.history.pushState({}, "", newPath);
            }
            setPath(newPath);
            window.scrollTo(0, 0);
        }
    };
    const contextValue = {
        path: path,
        changePath: changePath,
    };
    return (_jsx(RouterContext.Provider, { value: contextValue, children: children }));
};
export { Router, RouterContext };
