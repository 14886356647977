import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import PictureGameBoard from "../../components/common/PictureGameBoard";
import huynxios from "../../shared/Hyunxios";
import findMeToDTO from "./FindMeToDTO";
const defaultFindMe = {
    day: 0,
    winner: 0,
    startTime: "",
    endTime: "",
    gameType: "PICXEL",
    questionImageURL: "",
    answers: [],
};
const defaultFindMeAnswer = {
    id: 0,
    title: "",
    explain: "",
    imageURL: "",
    x: 0,
    y: 0,
};
const days = [
    "첫째 날",
    "둘째 날",
    "셋째 날",
    "넷째 날",
    "다섯째 날",
    "여섯째 날",
    "일곱째 날",
];
const AdminPage = () => {
    const [findmes, setFindMes] = useState(new Array(7).fill(0).map((_, index) => {
        return { ...defaultFindMe, day: index };
    }));
    const [day, setDay] = useState(0);
    const [answer, setAnswer] = useState(defaultFindMeAnswer);
    const [mode, setMode] = useState("findme");
    const [winnders, setWinnders] = useState([]);
    const [eventStartDay, setEventStartDay] = useState("");
    const [eventEndtDay, setEventEndtDay] = useState("");
    const [eventWinnderCount, setEventWinnerCount] = useState(0);
    const [findMeWinners, setFindMeWinnesr] = useState([]);
    function changeQuestionURL(url) {
        const newFindme = [...findmes];
        newFindme[day].questionImageURL = url;
        setFindMes(newFindme);
    }
    function changeMode(mode) {
        setMode(mode);
    }
    function changeAnswer(answer) {
        setAnswer(() => {
            return { ...answer };
        });
    }
    const winnerData = winnders.map((winner) => {
        return (_jsxs("tr", { children: [_jsx("td", { children: winner.ranking }), _jsx("td", { children: winner.phone }), _jsx("td", { children: winner.distance }), _jsx("td", { children: winner.selection })] }));
    });
    async function getWinners() {
        const response = await huynxios.post("/api/admin/racing/winners", {});
        setWinnders(response.data);
    }
    const tableData = findmes[day].answers.map((eachAnswer) => {
        return (_jsxs("tr", { children: [_jsx("td", { children: _jsxs("p", { children: [eachAnswer.y, " ", _jsx("br", {}), " ", eachAnswer.x] }) }), _jsx("td", { children: eachAnswer.title }), _jsx("td", { children: eachAnswer.explain }), _jsx("td", { children: eachAnswer.imageURL })] }));
    });
    const handleImageChange = (event, isQuestion = false) => {
        const file = event.target.files?.[0];
        if (file && file.type === "image/jpeg") {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const url = await imageToURL(file, isQuestion);
                alert(url);
                if (isQuestion) {
                    changeQuestionURL(url);
                }
            };
            reader.readAsDataURL(file);
        }
        else {
            alert("Please select a JPG file.");
        }
    };
    const findMeTableContent = findMeWinners.map((winner) => {
        return (_jsxs("tr", { children: [_jsx("td", { children: winner.day }), _jsx("td", { children: winner.phone })] }));
    });
    const content = mode === "findme" ? (_jsxs("div", { className: "flex flex-col gap-[20px] overflow-scroll", children: [_jsx("div", { className: "flex flex-col", children: _jsx("div", { className: "flex gap-[30px]", children: days.map((_day, index) => (_jsx(DayBtn, { day: index, data: _day, isOn: index === day, onclick: (day) => {
                            setDay(day);
                        } }, `${index}` + day))) }) }), _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uB2F9\uCCA8 \uC778\uC6D0" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "text", value: findmes[day].winner, onChange: (str) => {
                            const newFindme = [...findmes];
                            newFindme[day].winner = Number(str.target.value);
                            setFindMes(newFindme);
                        }, className: "border border-black p-1", placeholder: "Enter text here" }), _jsx("p", { className: "text-[14px]", children: "\uBA85" })] }), _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uC2DC\uC791 \uC2DC\uAC04" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "datetime-local", value: findmes[day].startTime, onChange: (str) => {
                            const newFindme = [...findmes];
                            newFindme[day].startTime = str.target.value;
                            setFindMes(newFindme);
                        }, className: "border border-black p-1", placeholder: "Enter text here" })] }), _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uC885\uB8CC \uC2DC\uAC04" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "datetime-local", value: findmes[day].endTime, onChange: (str) => {
                            const newFindme = [...findmes];
                            newFindme[day].endTime = str.target.value;
                            setFindMes(newFindme);
                        }, className: "border border-black p-1", placeholder: "Enter text here" })] }), _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uC815\uB2F5 \uC720\uD615" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "text", value: findmes[day].gameType, onChange: (str) => {
                            const newFindme = [...findmes];
                            newFindme[day].gameType = str.target.value;
                            setFindMes(newFindme);
                        }, className: "border border-black p-1", placeholder: "Enter text here" })] }), _jsxs("div", { className: "flex gap-[50px] relative", children: [_jsx("div", { className: "w-[473.7036px] h-[400px] bg-slate-300 overflow-hidden", children: _jsx(PictureGameBoard, { imageURL: findmes[day].questionImageURL, showingElements: [], onClickAction: (width, height, y, x) => {
                                width;
                                height;
                                const newAnswer = { ...answer };
                                newAnswer.y = y / 400;
                                newAnswer.x = x / 473.7036;
                                setAnswer(newAnswer);
                            } }) }), _jsx("div", { className: "w-1/4", children: _jsxs("table", { className: "w-full", children: [_jsxs("thead", { className: "bg-slate-200", children: [_jsx("td", { className: "border border-gray-600", children: "no" }), _jsx("td", { className: "border border-gray-600", children: "\uC815\uB2F5" }), _jsx("td", { className: "border border-gray-600", children: "\uC124\uBA85" }), _jsx("td", { className: "border border-gray-600", children: "\uC774\uBBF8\uC9C0 \uC18C\uC2A4" })] }), _jsx("tbody", { children: tableData }), _jsxs("tfoot", { children: [_jsx("td", { children: _jsx("input", { type: "text", value: `${answer.y}\n${answer.x}`, className: "border border-black p-1", placeholder: "Enter text here" }) }), _jsx("td", { children: _jsx("input", { type: "text", value: answer.title, onChange: (str) => {
                                                    const before = { ...answer };
                                                    before.title = str.target.value;
                                                    changeAnswer(before);
                                                }, className: "border border-black p-1", placeholder: "Enter text here" }) }), _jsx("td", { children: _jsx("input", { type: "text", value: answer.explain, onChange: (str) => {
                                                    const before = { ...answer };
                                                    before.explain = str.target.value;
                                                    changeAnswer(before);
                                                }, className: "border border-black p-1", placeholder: "Enter text here" }) }), _jsx("td", { children: _jsx("input", { type: "text", value: answer.imageURL, onChange: (str) => {
                                                    const before = { ...answer };
                                                    before.imageURL = str.target.value;
                                                    changeAnswer(before);
                                                }, className: "border border-black p-1", placeholder: "Enter text here" }) })] })] }) }), _jsx("div", { className: "absolute right-[20px] bottom-[20px] flex items-center justify-center bg-gray-400 w-[100px] h-[40px]", onClick: () => {
                            const newFindMe = [...findmes];
                            newFindMe[day].answers = [...newFindMe[day].answers, answer];
                            setFindMes(newFindMe);
                        }, children: _jsx("p", { children: "\uC800\uC7A5\uD558\uAE30" }) })] }), _jsx("div", { className: "bg-slate-200 flex justify-center items-center w-[200px] h-[100px]", onClick: async () => {
                    const response = await huynxios.get("/api/admin/finding/winner");
                    const winners = response;
                    setFindMeWinnesr(winners.data.winner);
                }, children: _jsx("p", { children: "let's \uB2F9\uCCA8" }) }), _jsx("table", { className: "w-1/2", children: _jsxs("thead", { className: "bg-slate-200", children: [_jsxs("tr", { children: [_jsx("th", { className: "w-1/3 border border-[black]", children: "gameIndex" }), _jsx("th", { className: "w-2/3 border border-[black]", children: "phone number" })] }), _jsx("tbody", { children: findMeTableContent })] }) })] })) : (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uC774\uBCA4\uD2B8 \uC0C1\uD0DC" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "text", className: "border border-black p-1", placeholder: "Enter text here" }), _jsx("div", { className: "w-[30px]" }), _jsx("div", { className: "bg-slate-300 flex justify-center items-center w-[200px] h-[40px]", onClick: () => getWinners(), children: _jsx("p", { children: "let's \uB2F9\uCCA8" }) })] }), _jsxs("table", { className: "w-full mt-[30px]", children: [_jsxs("thead", { className: "bg-slate-200", children: [_jsx("td", { className: "border border-gray-600", children: "\uB7AD\uD0B9" }), _jsx("td", { className: "border border-gray-600", children: "\uC804\uD654\uBC88\uD638" }), _jsx("td", { className: "border border-gray-600", children: "\uAC70\uB9AC" }), _jsx("td", { className: "border border-gray-600", children: "\uC635\uC158" })] }), _jsx("tbody", { children: winnerData })] })] }));
    _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uB2F9\uCCA8 \uC778\uC6D0" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "text", className: "border border-black p-1", placeholder: "Enter text here" }), _jsx("p", { className: "text-[14px]", children: "\uBA85" })] });
    return (_jsxs("div", { className: "flex flex-col p-[24px]", children: [_jsxs("div", { className: "flex justify-between justify-center items-center", children: [_jsx("p", { className: "text-[40px] font-bold", children: "\uC774\uBCA4\uD2B8 \uC124\uC815" }), _jsxs("div", { className: "flex gap-[20px]", children: [_jsx("input", { className: "bg-gray-200", type: "file", accept: "image/jpeg", onChange: (event) => handleImageChange(event, true) }), _jsx("input", { type: "file", accept: "image/jpeg", onChange: handleImageChange }), _jsx("div", { className: "bg-gray-400 w-[100px] h-[40px] flex items-center justify-center", onClick: async () => {
                                    await huynxios.post("/api/admin/finding/answer", findMeToDTO(findmes[day]));
                                }, children: _jsx("p", { children: "\uC800\uC7A5\uD558\uAE30" }) })] })] }), _jsx("p", { className: "text-[20px] font-semibold", children: "\uAE30\uBCF8 \uC124\uC815" }), _jsxs("div", { className: "flex items-center", children: [_jsx("p", { className: "text-[14px]", children: "\uC774\uBCA4\uD2B8 \uAE30\uAC04" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "datetime-local", className: "border border-black p-1", value: eventStartDay, onChange: (date) => {
                            console.log(date.target.value);
                            setEventStartDay(date.target.value);
                        }, placeholder: "Enter text here" }), _jsx("div", { className: "w-[30px]" }), _jsx("input", { type: "datetime-local", className: "border border-black p-1", value: eventEndtDay, onChange: (date) => {
                            setEventEndtDay(date.target.value);
                        }, placeholder: "Enter text here" }), _jsx("input", { type: "number", className: "border border-black mx-[20px]", value: eventWinnderCount, onChange: (date) => {
                            setEventWinnerCount(Number(date.target.value));
                        }, placeholder: "Enter text here" }), _jsx("div", { className: "w-[100px] h-[50px] bg-slate-200 flex justify-center items-center", onClick: async () => {
                            if (mode == "findme") {
                                const dateOnly = eventStartDay.split("T")[0];
                                await huynxios.post("/api/admin/finding/period", {
                                    startDate: dateOnly,
                                });
                            }
                            else {
                                const start = eventStartDay + ":00";
                                const end = eventEndtDay + ":00";
                                await huynxios.post("/api/admin/racing/period", {
                                    startTime: start,
                                    endTime: end,
                                    numberOfWinners: eventWinnderCount,
                                });
                            }
                        }, children: _jsx("p", { children: "\uC800\uC7A5\uD558\uAE30" }) })] }), _jsx("p", { className: "text-[20px] font-semibold", children: "\uC0C1\uC138 \uC774\uBCA4\uD2B8 \uC124\uC815" }), _jsxs("div", { className: "flex", children: [_jsx("p", { className: "text-[14px]", onClick: () => {
                            changeMode("findme");
                        }, children: "\uB098\uB97C \uCC3E\uC544\uBD10" }), _jsx("div", { className: "w-[30px]" }), _jsx("p", { className: "text-[14px]", onClick: () => {
                            changeMode("racinggame");
                        }, children: "\uC804\uB825\uC73C\uB85C 513km" })] }), content] }));
};
const DayBtn = ({ day, data, onclick, isOn = false, }) => {
    return (_jsx("div", { className: "flex justify-center items-center border border-black w-[150px] h-[40px]", onClick: () => onclick(day), children: isOn ? (_jsx("p", { className: "text-[14px] font-bold", children: data })) : (_jsx("p", { className: "text-[14px]", children: data })) }));
};
async function imageToURL(data, isQuestion = false) {
    const formdata = new FormData();
    formdata.append("file", data);
    formdata.append("directory", isQuestion ? "question" : "answer");
    const requestOptions = {
        method: "POST",
        body: formdata,
        headers: new Headers(),
        credentials: "include",
        redirect: "follow",
    };
    const response = await await fetch("http://43.201.185.99:80/api/admin/s3", requestOptions);
    const result = (await response.json());
    return result.data.imageUrl;
}
export default AdminPage;
