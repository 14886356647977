class Hyunxios {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }
    async fetch(url, options = null, limitTime = 60000) {
        const controller = new AbortController();
        const { signal } = controller;
        const fetchPromise = fetch(url, { ...options, signal }).then((response) => ({ type: "success", data: response }));
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                controller.abort();
                reject({ type: "error", data: "Request timed out" });
            }, limitTime);
        });
        let result = new Response();
        try {
            const raceResult = (await Promise.race([
                fetchPromise,
                timeoutPromise,
            ]));
            console.log(raceResult.type);
            if (raceResult.type === "error")
                throw new Error("Request timeout");
            result = raceResult.data;
        }
        catch (error) {
            console.log(`fetch error -> ${error}`);
        }
        return result;
    }
    async get(url) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        try {
            const response = await this.fetch(`${this.baseUrl}${url}`, {
                method: "GET",
                credentials: "include",
                headers: headers,
                redirect: "follow",
            });
            if (!response.ok) {
                throw new Error("request failed.");
            }
            const data = (await response.json());
            return data;
        }
        catch {
            throw new Error("parsing failed.");
        }
    }
    async post(url, parameter) {
        const jsonData = JSON.stringify(parameter);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        try {
            const response = await this.fetch(`${this.baseUrl}${url}`, {
                method: "POST",
                headers: headers,
                credentials: "include",
                body: jsonData,
                redirect: "follow",
            });
            if (!response.ok) {
                throw new Error("request failed.");
            }
            const data = (await response.json());
            return data;
        }
        catch {
            throw new Error("parsing failed.");
        }
    }
    async delete(url) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        try {
            const response = await this.fetch(`${this.baseUrl}${url}`, {
                method: "DELETE",
                headers: headers,
                credentials: "include",
                redirect: "follow",
            });
            if (!response.ok) {
                throw new Error("request failed.");
            }
            const data = (await response.json());
            return data;
        }
        catch {
            throw new Error("parsing failed.");
        }
    }
    async update(url, parameter) {
        const jsonData = JSON.stringify(parameter);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        try {
            const response = await this.fetch(`${this.baseUrl}${url}`, {
                method: "UPDATE",
                headers: headers,
                credentials: "include",
                body: jsonData,
                redirect: "follow",
            });
            if (!response.ok) {
                throw new Error("request failed.");
            }
            const data = (await response.json());
            return data;
        }
        catch {
            throw new Error("parsing failed.");
        }
    }
}
const huynxios = new Hyunxios("http://43.201.185.99:80");
export default huynxios;
