import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { RouterContext } from "../../../shared/Hyunouter/Router";
import Link from "../../../shared/Hyunouter/Link";
import "./Navigation.css";
const Navigation = () => {
    const { path } = useContext(RouterContext);
    const isActive = (linkPath) => path === linkPath;
    const [showNav, setShowNav] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY) {
                setShowNav(false);
            }
            else {
                setShowNav(true);
            }
            setLastScrollY(currentScrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollY]);
    // Todo : 정적으로 네비게이션 아이템만들기 (TBD)
    return (_jsxs("nav", { className: `${showNav ? "" : "hideNav"} relative bg-navigation flex justify-center items-center h-16 border-b-4 border-navigationUnderline header h-[8%] min-h-[60px]`, children: [_jsx("div", { className: "absolute left-0 ml-10 logo", children: _jsx(Link, { to: "/", children: _jsx("img", { src: "/assets/casperLogo.svg", alt: "casperLogo" }) }) }), _jsxs("ul", { className: "navbar flex list-none text-white space-x-28 h-full items-center", children: [_jsx("li", { className: `navbarMenu h-full flex justify-center items-center px-5 relative
            before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-white
            before:transform before:transition-transform before:duration-300 before:ease-in-out
            ${isActive("/")
                            ? "before:scale-x-100"
                            : "before:scale-x-0 hover:before:scale-x-50"}
          `, children: _jsx("div", { className: "h-full flex items-center", children: _jsx(Link, { to: "/", children: _jsx("span", { className: `font-bold ${isActive("/")
                                        ? "text-white"
                                        : "text-navigationText hover:text-white transition-colors duration-300"}`, children: "\uC774\uBCA4\uD2B8 \uC18C\uAC1C" }) }) }) }), _jsx("li", { className: `navbarMenu h-full flex justify-center items-center px-5 relative
            before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-white
            before:transform before:transition-transform before:duration-300 before:ease-in-out
            ${isActive("/findcasper")
                            ? "before:scale-x-100"
                            : "before:scale-x-0 hover:before:scale-x-50"}
          `, children: _jsx("div", { className: "h-full flex items-center", children: _jsx(Link, { to: "/findcasper", children: _jsx("span", { className: `font-bold ${isActive("/findcasper")
                                        ? "text-white"
                                        : "text-navigationText hover:text-white transition-colors duration-300"}`, children: "\uB098\uB97C \uCC3E\uC544\uBD10" }) }) }) }), _jsx("li", { className: `navbarMenu h-full flex justify-center items-center px-5 relative
            before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-white
            before:transform before:transition-transform before:duration-300 before:ease-in-out
            ${isActive("/racecasper")
                            ? "before:scale-x-100"
                            : "before:scale-x-0 hover:before:scale-x-50"}
          `, children: _jsx("div", { className: "h-full flex items-center", children: _jsx(Link, { to: "/racecasper", children: _jsx("span", { className: `font-bold ${isActive("/racecasper")
                                        ? "text-white"
                                        : "text-navigationText hover:text-white transition-colors duration-300"}`, children: "\uC804\uB825\uC73C\uB85C 315Km" }) }) }) })] })] }));
};
export default Navigation;
