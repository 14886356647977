import { jsx as _jsx } from "react/jsx-runtime";
const Circle = ({ radius, color }) => {
    const circleStyle = {
        width: `${radius}px`,
        height: `${radius}px`,
        backgroundColor: `#${color}`,
    };
    return (_jsx("div", { className: "flex items-center justify-center", children: _jsx("div", { className: "rounded-full", style: circleStyle }) }));
};
export default Circle;
