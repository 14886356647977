import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
const InfoSection = ({ type = "Header", title = "", children, width = 100, }) => {
    let header = null;
    switch (type) {
        case "Header":
            header = (_jsxs("div", { className: "flex justify-center items-center", children: [_jsx("img", { src: "/assets/sectionContentHeader.svg", alt: "howToEventLeft" }), _jsx("p", { className: "absolute text-[white] font-galmuri text-[28px]", children: title })] }));
            break;
        case "Default":
            header = _jsx("img", { src: "/assets/topLine.svg", alt: "topLine" });
            break;
    }
    const style = {
        width: `${width}%`,
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col items-center justify-center h-full", style: style, children: [header, _jsxs("div", { className: "border-l-4 border-r-4 border-white relative w-full h-full", children: [children, _jsx(InfoSectionDot, { top: 20, left: 20 }, 0), _jsx(InfoSectionDot, { top: 20, right: 20 }, 1), _jsx(InfoSectionDot, { bottom: 20, left: 20 }, 2), _jsx(InfoSectionDot, { bottom: 20, right: 20 }, 3)] }), _jsx("img", { src: "/assets/bottomLine.svg", alt: "howToEventRight" })] }) }));
};
const InfoSectionDot = ({ top, bottom, left, right }) => {
    const style = {
        width: "20px",
        position: "absolute",
        zIndex: 10,
        ...(top !== undefined && { top: `${top}px` }),
        ...(bottom !== undefined && { bottom: `${bottom}px` }),
        ...(left !== undefined && { left: `${left}px` }),
        ...(right !== undefined && { right: `${right}px` }),
    };
    return (_jsx("img", { src: "/assets/infoSectionPoint.svg", alt: "infoSectionPoint", style: style }));
};
export default InfoSection;
