import React, { useContext, isValidElement, } from "react";
import { RouterContext } from "./Router";
const Routes = ({ children }) => {
    const { path } = useContext(RouterContext);
    let element = null;
    React.Children.forEach(children, (child) => {
        if (!isValidElement(child)) {
            return;
        }
        if (child.type === React.Fragment) {
            return;
        }
        if (!child.props.path || !child.props.element) {
            return;
        }
        if (child.props.path !== path) {
            return;
        }
        element = child.props.element;
    });
    return element;
};
export default Routes;
