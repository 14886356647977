import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { initOverlayState, overlayReducer, action, } from "../../../jobs/Overlay/OverlayWork";
import findChildrenElement from "../../../utils/FindChildrenElement";
import { store, useWork } from "../../../shared/Hyundux";
const OverLay = ({ children }) => {
    const [state, dispatch] = useWork(initOverlayState, overlayReducer);
    dispatch;
    const content = findChildrenElement(children, (element) => element.props.index !== undefined && element.props.index == state.index);
    if (state.isShowing) {
        return (_jsxs("div", { className: "absolute z-[100] inset-0 flex flex-col items-center justify-center", children: [_jsx("div", { className: "absolute bg-black opacity-50 w-full h-full" }), _jsxs("div", { className: "relative bg-white shadow-lg z-10", style: { width: "62%", height: "71%" }, children: [_jsx("img", { src: "/assets/xButton.svg", className: "absolute right-10 top-10", onClick: () => {
                                // Todo: store 지우기
                                store.dispatch(action.toggleOverlay());
                            } }), content] })] }));
    }
    else {
        return null;
    }
};
export default OverLay;
