import { createState, makePayLoad, } from "../../shared/Hyundux";
const WORK_NAME = "FindingGame";
const initFindingGameState = createState(WORK_NAME, {
    imageURL: "",
    gameType: "PIXEL",
    gameStatus: "Gaming",
    answerIndex: 0,
    ticketId: "-1",
    showingAnswers: [],
    wrongAnswers: [],
    showingHint: [],
});
// define reducer
const findingGameReducer = {
    type: WORK_NAME,
    reducer: async function reducer(state, action) {
        const payLoad = state.payload;
        switch (action.actionName) {
            case "init": {
                const actionPayload = action.payload;
                return makePayLoad(state, {
                    imageURL: actionPayload.data.info.questionImageUrl,
                    gameType: actionPayload.data.info.answerType,
                });
            }
            case "click": {
                const actionPayLoad = action.payload;
                const request = actionPayLoad.request;
                const response = actionPayLoad.response;
                const reponseData = response.data;
                if (state.payload.showingAnswers.length !=
                    reponseData.correctAnswerList.length) {
                    if (reponseData.correctAnswerList.length == 2) {
                        return makePayLoad(state, {
                            showingHint: [],
                            ticketId: reponseData.ticketId,
                            gameStatus: reponseData.ticketId === "-1" ? "DoneFail" : "DoneSuccess",
                            showingAnswers: reponseData.correctAnswerList,
                        });
                    }
                    return makePayLoad(state, {
                        showingHint: [],
                        showingAnswers: reponseData.correctAnswerList,
                    });
                }
                return makePayLoad(state, {
                    wrongAnswers: [
                        ...state.payload.wrongAnswers,
                        {
                            id: Math.round(Math.random() * 1000),
                            y: request.answerList[request.answerList.length - 1].positionY,
                            x: request.answerList[request.answerList.length - 1].positionX,
                        },
                    ],
                });
            }
            case "removeWrongAnswer": {
                const actionPayLoad = (action.payload || {});
                const _wrongAnswers = payLoad.wrongAnswers.filter((answer) => answer.id !== actionPayLoad.id);
                return makePayLoad(state, { wrongAnswers: _wrongAnswers });
            }
            case "showHint": {
                const payLoad = action.payload;
                const response = payLoad.response.data;
                const hint = {
                    positionX: response.hintPosition.positionX,
                    positionY: response.hintPosition.positionY,
                    descriptionImageUrl: "",
                    title: "",
                    content: "",
                };
                return makePayLoad(state, { showingHint: [hint] });
            }
            case "changeShowingAnswer": {
                const actionPayLoad = (action.payload || {});
                return makePayLoad(state, { answerIndex: actionPayLoad.answerIndex });
            }
            default:
                return state;
        }
    },
};
// actions
const action = {
    init: (payLoad) => {
        const reponse = payLoad.response;
        return {
            type: WORK_NAME,
            actionName: "init",
            payload: reponse,
        };
    },
    click: (payLoad) => {
        return {
            type: WORK_NAME,
            actionName: "click",
            payload: payLoad,
        };
    },
    checkAnswer: (object) => {
        const payLoad = object;
        return {
            type: WORK_NAME,
            actionName: "checkAnswer",
            payload: payLoad,
        };
    },
    removeWrongAnswer: (id) => {
        return {
            type: WORK_NAME,
            actionName: "removeWrongAnswer",
            payload: {
                id: id,
            },
        };
    },
    showHint: (payLoad) => {
        return {
            type: WORK_NAME,
            actionName: "showHint",
            payload: payLoad,
        };
    },
    changeShowingAnswer: (index) => {
        return {
            type: WORK_NAME,
            actionName: "changeShowingAnswer",
            payload: {
                answerIndex: index,
            },
        };
    },
};
export function genrateFindGameAnswerCheckBodyParameter(state, y, x, width, height) {
    const result = state.showingAnswers.map((answer) => {
        return {
            positionY: answer.positionY,
            positionX: answer.positionX,
        };
    });
    return {
        answerList: [...result, { positionY: y / height, positionX: x / width }],
    };
}
export { action, initFindingGameState, findingGameReducer };
