import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { action as overlayAction } from "../../jobs/Overlay/OverlayWork";
import { OverLay, OverLayContent } from "../../components/common/Overlay/index";
import { store, useWork } from "../../shared/Hyundux";
import RacingGame from "../../components/RacingGame/index";
import { initRacingGameState, racingGameReducer, } from "../../jobs/RacingGame/RacingGameWork";
import SelectCustom from "./Enter/SelectCustom";
import EnterComplete from "./Enter/EnterComplete";
import PhoneNumberOverlay from "../../components/PhoneNumberOverlay";
import huynxios from "../../shared/Hyunxios";
const RacingGamePage = () => {
    const [state, dispatch] = useWork(initRacingGameState, racingGameReducer);
    const [phoneNumber, setPhoneNumber] = useState("");
    dispatch;
    useEffect(() => {
        if (state.gameStatus === "enterEvent") {
            store.dispatch(overlayAction.toggleOverlay());
        }
    }, [state.gameStatus]);
    return (_jsxs("div", { className: "flex flex-row h-full relative", children: [_jsxs(OverLay, { children: [_jsx(OverLayContent, { index: 0, element: _jsx(PhoneNumberOverlay, { type: "raceCasper", submitNumber: async (phoneNumber, action) => {
                                const response = await huynxios.post("/api/racing/result", {
                                    phone: phoneNumber,
                                    distance: state.distance,
                                });
                                console.log(response);
                                setPhoneNumber(phoneNumber);
                                store.dispatch(action(response.data.isOptionSelected ? 2 : 1));
                            } }) }), _jsx(OverLayContent, { index: 1, element: _jsx(SelectCustom, { phoneNumber: phoneNumber }) }), _jsx(OverLayContent, { index: 2, element: _jsx(EnterComplete, {}) })] }), _jsx(RacingGame, {})] }));
};
export default RacingGamePage;
