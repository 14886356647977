import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useRef } from "react";
import Lottie from "lottie-react";
const LottieContainer = ({ jsonFile, x, y, width, height, onAnimationEnd = () => { }, }) => {
    const lottieRef = useRef(null);
    const left = x - width / 2;
    const top = y - height / 2;
    return (_jsx("div", { style: {
            width: width,
            height: height,
            left: `${left}px`,
            top: `${top}px`,
            position: "absolute",
        }, className: "", children: _jsx(Lottie, { loop: false, lottieRef: lottieRef, animationData: jsonFile, autoplay: true, onComplete: onAnimationEnd }) }));
};
export default memo(LottieContainer);
