import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Navigation from "../../components/common/Navigation";
import { EventIntro, HowToEvent, GiftInfo, EventPeriod, EventPrecaution, } from "../../features/RacingGameLanding";
import Footer from "../../components/common/Footer";
import getRacingGameAvailable from "../../stories/RacingGame/getRacingGameAvailable";
const RacingGameLandingPage = () => {
    const [isEventOpen, setIsEventOpen] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const response = await getRacingGameAvailable();
            const racingGameOpen = response.data;
            if (racingGameOpen === true) {
                setIsEventOpen(true);
            }
            else {
                setIsEventOpen(false);
            }
        };
        fetchData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Navigation, {}), _jsx(EventIntro, { isEventOpen: isEventOpen }), _jsx(HowToEvent, {}), _jsx(GiftInfo, {}), _jsx(EventPeriod, { isEventOpen: isEventOpen }), _jsx(EventPrecaution, {}), _jsx(Footer, {})] }));
};
export default RacingGameLandingPage;
