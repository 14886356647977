import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FindingGame, FindingGameInfo } from "../../components/FindingGame";
import { findingGameReducer, initFindingGameState, } from "../../jobs/FindingGame/FindingGameWork";
import { action as overlayAction } from "../../jobs/Overlay/OverlayWork";
import { OverLay, OverLayContent } from "../../components/common/Overlay/index";
import EnterContent from "./Enter/EnterContent";
import { store, useWork } from "../../shared/Hyundux";
import FailContent from "./Enter/FailContent";
import SuccessEnterContent from "./Enter/SuccessEnterContent";
import FindingGameResult from "../../components/FindingGame/FindingGameResult";
import PhoneNumberOverlay from "../../components/PhoneNumberOverlay/PhoneNumberOverlay";
import huynxios from "../../shared/Hyunxios";
const FindingGamePage = () => {
    const [gameState, dispatch] = useWork(initFindingGameState, findingGameReducer);
    dispatch;
    useEffect(() => {
        if (gameState.showingAnswers.length === 2) {
            // Todo: store 지우기
            store.dispatch(overlayAction.toggleOverlay());
        }
    }, [gameState.showingAnswers.length]);
    let content = null;
    if (gameState.gameStatus == "DoneSuccess") {
        console.log(gameState.gameStatus);
        content = (_jsxs(OverLay, { children: [_jsx(OverLayContent, { index: 0, element: _jsx(EnterContent, {}) }), _jsx(OverLayContent, { index: 1, element: _jsx(PhoneNumberOverlay, { type: "findCasper", submitNumber: async (phoneNumber, action) => {
                            await huynxios.post("/api/finding/register", {
                                ticketId: gameState.ticketId,
                                phone: phoneNumber,
                            });
                            store.dispatch(action(1));
                        } }) }), _jsx(OverLayContent, { index: 2, element: _jsx(SuccessEnterContent, {}) })] }));
    }
    else if (gameState.gameStatus == "DoneFail") {
        console.log(gameState.gameStatus);
        content = (_jsx(OverLay, { children: _jsx(OverLayContent, { index: 0, element: _jsx(FailContent, {}) }) }));
    }
    console.log(gameState.gameStatus);
    return (_jsxs("div", { className: "relative flex flex-row h-full", children: [content, _jsx("div", { className: "w-[66%] h-full", children: _jsx(FindingGame, {}) }), _jsx("div", { className: "w-[34%] h-screen flex justify-center items-center", children: gameState.gameStatus == "Gaming" ? (_jsx(FindingGameInfo, {})) : (_jsx(FindingGameResult, {})) })] }));
};
export default FindingGamePage;
