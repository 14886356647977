import { createState } from "../../shared/Hyundux/State";
import { makePayLoad } from "../../shared/Hyundux/Util/StoreUtil";
const WORKFLOW_NAME = "RacingGame";
const km315 = 315;
const aniMovingDistance = 11990;
const initRacingGameState = createState(WORKFLOW_NAME, {
    gameStatus: "previous",
    topRate: 0,
    distance: 0,
});
// define reducer
const racingGameReducer = {
    type: WORKFLOW_NAME,
    reducer: async function reducer(state, action) {
        switch (action.actionName) {
            case "gameStart":
                return makePayLoad(state, { gameStatus: "playing" });
            case "gameEnd":
                {
                    const payload = action.payload;
                    const topRate = payload.response.data.percent;
                    return makePayLoad(state, { gameStatus: "end", topRate: topRate });
                }
                ;
            case "updateDistance":
                {
                    const actionPayLoad = action.payload;
                    // frontBackground 이미지가 애니메이션을 통해 이동한 거리를 실제 Km 단위로 변환해서 계산
                    return makePayLoad(state, {
                        distance: (actionPayLoad.distance / aniMovingDistance) * km315,
                    });
                }
                ;
            case "enterEvent":
                return makePayLoad(state, { gameStatus: "enterEvent" });
            default:
                return state;
        }
    },
};
// actions
const action = {
    gameStart: () => {
        return {
            type: WORKFLOW_NAME,
            actionName: "gameStart",
        };
    },
    gameEnd: (payload) => {
        return {
            type: WORKFLOW_NAME,
            actionName: "gameEnd",
            payload: {
                response: payload.response
            }
        };
    },
    updateDistance: (distance) => {
        return {
            type: WORKFLOW_NAME,
            actionName: "updateDistance",
            payload: {
                distance: distance,
            },
        };
    },
    enterEvent: () => {
        return {
            type: WORKFLOW_NAME,
            actionName: "enterEvent",
        };
    },
};
export { action, initRacingGameState, racingGameReducer };
