import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InfoSection from "../../components/common/InfoSection/index";
import { Link } from "../../shared/Hyunouter";
const EventPeriod = ({ isEventOpen }) => {
    const checkEventOpen = () => {
        if (!isEventOpen) {
            alert("지금은 이벤트 기간이 아닙니다!");
        }
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "relative h-[1100px] bg-black ", children: _jsxs("div", { className: "absolute inset-0 z-10 flex flex-col items-center w-full bg-[#00113F] bg-opacity-40", children: [_jsx("h1", { className: "text-white font-[900] text-[40px] mt-16", children: "\uC774\uBCA4\uD2B8 \uAE30\uAC04" }), _jsx("div", { className: "mt-14 px-20", children: _jsx(InfoSection, { title: "7\uC77C\uAC04 \uB9E4\uC77C\uB9E4\uC77C", children: _jsxs("div", { className: "flex flex-col justify-center items-center py-[60px] px-[60px]", children: [_jsxs("p", { className: "text-white text-center text-[22px] line-[140%]", children: ["\uCC38\uC5EC \uAE30\uAC04: 8.26 (\uC6D4) - 9.1 (\uC77C)", _jsx("br", {}), "\uB2F9\uCCA8\uC790 \uBC1C\uD45C \uB0A0\uC9DC: 9.2 (\uD654)\uBD80\uD130 \uC8FC\uB9D0\u318D\uACF5\uD734\uC77C \uC81C\uC678 \uC21C\uCC28\uC801\uC73C\uB85C \uBC1C\uC1A1"] }), _jsx("div", { className: "my-16", children: _jsx("img", { src: "/assets/eventPeriodBackground.svg", alt: "eventPeriodBackground" }) }), _jsx("div", { onClick: checkEventOpen, children: _jsx(Link, { to: isEventOpen ? "/racecaspergame" : "/racecasper", children: _jsx("div", { className: "mt-2", children: _jsx("img", { src: "/assets/play315GameButton.svg", alt: "play315GameButton" }) }) }) })] }) }) })] }) }) }));
};
export default EventPeriod;
