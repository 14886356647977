import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { action } from "../../../jobs/Overlay/OverlayWork";
import { setRacingGameOption } from "../../../stories/RacingGame/getRacingGameOption";
import { store } from "../../../shared/Hyundux";
const SelectCustom = ({ phoneNumber }) => {
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const handleOptionSelect = (id) => {
        setSelectedOptionId(id);
    };
    return (_jsxs("div", { className: "flex flex-col w-full h-full", children: [_jsxs("div", { className: "px-[60px] pt-[70px] grow", children: [_jsx("div", { className: "text-[32px] font-bold self-start", children: "\uAE30\uB300\uB418\uB294 \uC635\uC158 \uC120\uD0DD\uD558\uACE0 \uCD94\uAC00 \uB2F9\uCCA8 \uD655\uB960 \uB192\uC774\uAE30!" }), _jsxs("div", { className: "text-[18px] self-start mt-[10px]", children: ["\uC6D0\uD558\uB294 \uCE90\uC2A4\uD37C \uC77C\uB809\uD2B8\uB9AD \uCEE4\uC2A4\uD130\uB9C8\uC774\uC9D5 \uC635\uC158\uC744 \uACE8\uB77C\uC8FC\uC138\uC694.", _jsx("br", {}), "\uCE90\uC2A4\uD37C \uC77C\uB809\uD2B8\uB9AD \uB2F9\uCCA8 \uC2DC \uC120\uD0DD\uD55C \uC635\uC158\uC73C\uB85C \uBC1B\uAC8C \uB3FC\uC694."] }), _jsx("div", { className: "flex flex-row gap-3 mt-5", children: options.map((option) => (_jsxs("div", { className: "flex flex-col cursor-pointer", onClick: () => handleOptionSelect(option.id), children: [_jsx("div", { className: "mt-2 min-w-[150px] min-h-[200px] h-auto", children: _jsx("img", { src: option.imgSrc, alt: `option${option.id}` }) }), _jsxs("div", { className: "relative mt-2", children: [_jsx("img", { src: selectedOptionId === option.id
                                                ? "/assets/customSelectDescriptionFocus.svg"
                                                : "/assets/customSelectDescription.svg", alt: `option${option.id}` }), _jsxs("div", { className: "absolute inset-0 top-2 flex flex-col justify-center items-center text-center p-1", children: [_jsx("div", { className: selectedOptionId === option.id
                                                        ? "text-white font-bold text-[13px]"
                                                        : "text-[#1C1A1B] font-bold text-[13px]", children: option.title }), _jsx("div", { className: selectedOptionId === option.id
                                                        ? "text-[#D9D9D9] text-[11px] mt-1 whitespace-pre-line"
                                                        : "text-[#666666] text-[11px] mt-1 whitespace-pre-line", children: option.description })] })] }), _jsx("div", { className: "text-center mt-4", children: _jsxs("label", { className: "relative inline-flex items-center cursor-pointer", children: [_jsx("input", { type: "radio", name: "custom-option", value: option.id, checked: selectedOptionId === option.id, onChange: () => handleOptionSelect(option.id), className: "sr-only peer" }), _jsx("div", { className: `w-8 h-8 rounded-full border-2 
                                        ${selectedOptionId === option.id
                                                    ? "border-[#002C5F]"
                                                    : "border-gray-300"} 
                                        flex items-center justify-center
                                        peer-checked:border-[#002C5F]`, children: selectedOptionId === option.id && (_jsx("div", { className: "w-5 h-5 rounded-full bg-[#002C5F]" })) })] }) })] }, option.id))) })] }), selectedOptionId !== null ? (_jsx("div", { onClick: () => {
                    setRacingGameOption(phoneNumber, selectedOptionId);
                    store.dispatch(action.nextPage());
                }, className: "bg-[#002C5F] h-[12%] flex items-center justify-center hover:cursor-pointer", children: _jsx("p", { className: "text-white text-[20px] font-bold", children: "\uD655\uC778" }) })) : (_jsx("div", { className: "bg-[#CCCCCC] h-[12%] flex items-center justify-center", children: _jsx("p", { className: "text-white text-[20px] font-bold", children: "\uB9C8\uC74C\uC5D0 \uB4DC\uB294 \uCEE4\uC2A4\uD140\uB9C8\uC774\uC9D5\uC744 \uC120\uD0DD\uD558\uC138\uC694" }) }))] }));
};
export default SelectCustom;
const options = [
    {
        id: 1,
        imgSrc: "/assets/racingGameCase1Image.svg",
        title: "Case 1. 공간활용의 기술",
        description: "캐스퍼 일렉트릭의 구석구석을\n활용해 많은 물건도 알차게 실을래요.",
    },
    {
        id: 2,
        imgSrc: "/assets/racingGameCase2Image.svg",
        title: "Case 2. 레저의 정석",
        description: "캐스퍼 일렉트릭과 함께 방방곡곡\n누빌 레저 라이프가 기대되어요.",
    },
    {
        id: 3,
        imgSrc: "/assets/racingGameCase3Image.svg",
        title: (_jsxs("span", { children: ["Case 3. \uC5EC\uD589\uC758 \uC815\uC11D ", _jsx("span", { className: "text-[10px]", children: "Camping" })] })),
        description: "캐스퍼 일렉트릭과 함께 아웃도어\n활동을 쉽고 편안하게 할래요.",
    },
    {
        id: 4,
        imgSrc: "/assets/racingGameCase4Image.svg",
        title: (_jsxs("span", { children: ["Case 4. \uC5EC\uD589\uC758 \uC815\uC11D ", _jsx("span", { className: "text-[10px]", children: "Picnic" })] })),
        description: "캐스퍼 일렉트릭과 함께하는 즐거운\n피크닉이 기대되어요.",
    },
    {
        id: 5,
        imgSrc: "/assets/racingGameCase5Image.svg",
        title: "Case 5. 펫 프렌들리",
        description: "캐스퍼 일렉트릭으로 반려동물과\n편안하고 안전한 여행을 할래요.",
    },
];
