import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import useComponentPosition from "../../../hooks/useComponentRect";
const PictureGameBoard = ({ imageURL, showingElements = [], onClickAction, setRect = () => { }, }) => {
    const [ref, position] = useComponentPosition();
    useEffect(() => {
        setRect(position.width, position.height);
    }, [position]);
    const handleClick = (event) => {
        const { y, x } = {
            y: event.clientY - position.y,
            x: event.clientX - position.x,
        };
        onClickAction(position.width, position.height, y, x);
    };
    return (_jsxs("div", { ref: ref, onClick: handleClick, className: "relative", children: [_jsx("div", { className: "absolute inset-0 z-20 w-full h-full", children: showingElements }), _jsx("div", { className: "relative w-full h-screen", children: _jsx("img", { src: imageURL, alt: "Finding Picture", className: "absolute inset-0 w-full object-fit z-10 object-left" }) })] }));
};
export default PictureGameBoard;
