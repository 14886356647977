export default function findMeToDTO(data) {
    const answers = data.answers.map((answer) => {
        return {
            coordX: answer.x,
            coordY: answer.y,
            descriptionImageUrl: answer.imageURL,
            title: answer.title,
            content: answer.explain,
        };
    });
    return {
        dayOfEvent: data.day + 1,
        numberOfWinner: 315,
        questionImageUrl: data.questionImageURL,
        startTime: "15:15:00",
        endTime: "14:15:00",
        answerType: data.gameType,
        answerInfoList: answers,
    };
}
