import { useState } from "react";
import { saga } from "./Saga";
const useSaga = () => {
    const [sagaStatus, setSagaStatus] = useState("isLoading");
    return [
        sagaStatus,
        async (action, story, parameter = {}) => {
            setSagaStatus("isLoading");
            try {
                await saga.run(action, story, parameter);
            }
            catch (e) {
                console.log(`saga Error: ${e}`);
                setSagaStatus("isError");
            }
            setSagaStatus("isSuccess");
        },
    ];
};
export default useSaga;
