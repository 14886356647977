import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { RouterContext } from "./Router";
const Link = ({ to, children, isPathChage = true }) => {
    const { changePath } = useContext(RouterContext);
    const handleClick = (event) => {
        event.preventDefault();
        changePath(to, isPathChage);
    };
    return (_jsx("a", { href: to, onClick: handleClick, children: children }));
};
export default Link;
