import { jsx as _jsx } from "react/jsx-runtime";
import "./HintSpot.css";
const HintSpot = ({ y, x }) => {
    const style = {
        position: "absolute",
        zIndex: 20,
        top: `${y - 25}px`,
        left: `${x - 25}px`,
        width: "50px",
        animation: "fade-in-out 1s ease-in-out infinite",
    };
    return (_jsx("img", { src: "/assets/hintSpot.svg", alt: "hintImg", style: style, className: "fade-in-out" }));
};
export default HintSpot;
