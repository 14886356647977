import { useEffect, useRef } from "react";
const useKeyBoardControl = (key, callback) => {
    const ref = useRef(null);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === key) {
                callback(event);
            }
        };
        if (ref.current) {
            ref.current?.focus();
            ref.current?.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            if (ref.current) {
                ref.current.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [key, callback]);
    return ref;
};
export default useKeyBoardControl;
